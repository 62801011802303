






















import {Component, VModel, Vue} from 'vue-property-decorator';

@Component({
  components: {
    RJTextField: () => import(
        '@/components/shared/custom-vuetify/RJTextField.vue'),
  },
})
export default class SearchBarComponent extends Vue {
  /**
   * This is the value of the text-field that is used to search for items
   * @private
   */
  @VModel({required: true, type: String})
  private searchString!: string;
  /**
   * Bool to open the searchBar
   * @private
   */
  private search: boolean = false;
  /**
   * This prop prevents the closing animation to be executed at initial loading. It starts with a width of 0
   * @private
   */
  private initial: boolean = true;
  /**
   * Getter to get the searchInputElement($ref does not work here)
   */
  get searchInputElement(): HTMLInputElement {
    return document.querySelectorAll('[data-search-input]')[0] as HTMLInputElement;
  }
  /**
   * Method to invoke on searchButtonClick
   * @private
   */
  private onSearchClick() {
    this.search = !this.search;
    if (this.search) {
      this.initial = false;
      this.$nextTick(() => {
        this.searchInputElement.focus();
      });
    }
  }

}
